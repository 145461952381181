// New metrics should be adding in alphabetical order
const AdvertisingMetrics = [
    { name: 'Campaign', key: 'campaign_id', format: 'number' },
    { name: 'Campaign Status', key: 'campaign_entity_status', format: 'text' },
    { name: 'Clicks', key: 'total_clickthrough', format: 'number' },
    { name: 'Click Rate', key: 'total_clickthrough_rate', format: 'percent' },
    { name: 'Cost Per Click', alias: 'CPC', key: 'total_clickthrough_cost_per', format: 'currency' },
    { name: 'Cost Per Lead(CPL)', alias: 'CPL', key: 'total_lead_cost_per', format: 'currency' },
    { name: 'Cost Per Offline Purchase', key: 'cps', format: 'currency' },
    { name: 'Cost Per Mille(CPM)', alias: 'CPM', key: 'cpm', format: 'currency' },
    { name: 'Cost Per Signup', key: 'total_signup_cost_per', format: 'currency' },
    { name: 'Impressions', key: 'impressions', format: 'number' },
    { name: 'Impression Frequency', key: 'total_impressions_frequency', format: 'number' },
    { name: 'Leads', key: 'total_lead', format: 'number' },
    { name: 'Leads Rate', key: 'total_lead_rate', format: 'percent' },
    { name: 'Offline Purchase', key: 'total_offline_checkout', format: 'number' },
    { name: 'Offline Purchase Rate', key: 'conversion_rate', format: 'percent' },
    { name: 'Signup Rate', key: 'total_signup_rate', format: 'percent' },
    { name: 'Spend', key: 'spend', format: 'currency' },
    { name: 'Total Signups', key: 'total_signup', format: 'number' },
    { name: 'Content Views', key: 'total_add_to_cart', format: 'number' },
    { name: 'Content View Rate', key: 'total_add_to_cart_rate', format: 'percent' },
    { name: 'Cost Per Content View', key: 'total_add_to_cart_cost_per', format: 'currency' },
];

export default AdvertisingMetrics;
