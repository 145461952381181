<template>
    <div>
        <div
            v-for="(page,p) in pages"
            :key="p"
            class="page-editor">
            <div class="page-header">
                <p class="page-title">
                    {{ page.title }}
                </p>
                <button
                    class="page-settings-toggle"
                    @click="showPageSettings(p)">
                    <icon name="tool" />
                    <span class="text">Page Settings</span>
                </button>
            </div>
            <component
                :is="pageComponent"
                class="in-editor"
                :page="page"
                @show-page-settings="showPageSettingsWithIndex" />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Icon from '@/components/globals/Icon';

export default {
    name: 'ReportEditor',
    components: {
        Icon,
        PageEditor: () => import('./PageEditor'),
        PagePreview: () => import('./PagePreview')
    },
    props: {
        showReportPreview: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            offset: 0,
            pageHeight: 896,
            buffer: 250,
        };
    },
    computed: {
        ...mapState({
            pages: (state) => state.report.template.pages
        }),
        pageComponent() {
            return this.showReportPreview ? 'PagePreview' : 'PageEditor';
        },
        visiblePageIndex() {
            return Math.floor(this.offset / this.pageHeight);
        },
    },
    watch: {
        visiblePageIndex(value) {
            this.setActivePageIndex(value);
        }
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        ...mapActions([
            'setActivePageIndex',
        ]),
        handleScroll() {
            this.offset = window.scrollY;
        },
        setActive(index) {
            this.setActivePageIndex(index);
        },
        showPageSettings(index) {
            this.setActivePageIndex(index);
            this.scrollToPage(index);
            this.$emit('showPageSettings', 0);
        },
        showPageSettingsWithIndex(regionIndex) {
            this.$emit('showPageSettings', regionIndex);
        },
        scrollToPage(index) {
            const pageHeight = 940;
            window.scrollTo({ top: index * pageHeight, behavior: 'smooth' });
        },
    }
};
</script>

<style lang="scss" scoped>
.page-editor {
    position: relative;
    width: 1056px;
    margin-top: 20px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
}
.page-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.page-title {
    font-size: 16px;
}
.page-settings-toggle {
    display: flex;
    flex-direction: row;
    padding: 10px;
    width: 130px;
    border-radius: 9px 9px 0 0;
    align-items: center;
    background: #F7F8FC;
    border: 1px solid #D3D9DC;
    border-bottom: none;
    cursor: pointer;
    .text {
        margin-left: 15px;
        text-transform: uppercase;
        font-size: 9px;
        font-weight: 600;
    }
}
</style>