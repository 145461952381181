// New metrics should be adding in alphabetical order
const AdvertisingMetrics = [
    { name: 'Call Confirmation Click ', key: 'click_to_call_call_confirm', format: 'number' },
    { name: 'Call Confirmations', key: 'call_confirmation', format: 'number' },
    { name: 'Click To Call', key: 'click_to_call', format: 'number' },
    { name: 'Clicks', key: 'clicks', format: 'number' },
    { name: 'Content View Rate', key: 'content_view_rate', format: 'percent' },
    { name: 'Content Views', key: 'content_views', format: 'number' },
    { name: 'Cost Per Click', alias: 'CPC', key: 'cost_per_click', format: 'currency' },
    { name: 'Cost Per Content View', key: 'cost_per_content_view', format: 'currency' },
    { name: 'Cost Per Form Lead', key: 'cost_per_form_lead', format: 'currency' },
    { name: 'Cost Per Landing Page View', alias: 'CPLPV', key: 'cost_per_landing_page_view', format: 'currency' },
    { name: 'Cost Per Lead', alias: 'CPL', key: 'cost_per_lead', format: 'currency' },
    { name: 'Cost Per Offline Add Payment Info', key: 'cost_per_offline_add_payment_info', format: 'currency' },
    { name: 'Cost Per Offline Add To Cart', key: 'cost_per_offline_add_to_cart', format: 'currency' },
    { name: 'Cost Per Offline Add To Wish List', key: 'cost_per_offline_add_to_wish_list', format: 'currency' },
    { name: 'Cost Per Offline Initiate Checkout', key: 'cost_per_offline_initiate_checkout', format: 'currency' },
    { name: 'Cost Per Offline Lead', key: 'cost_per_offline_lead', format: 'currency' },
    { name: 'Cost Per Offline Other', key: 'cost_per_offline_other', format: 'currency' },
    { name: 'Cost Per Offline Purchase', key: 'cost_per_offline_purchase', format: 'currency' },
    { name: 'Cost Per Offline Purchase 28d', key: 'cost_per_offline_purchase_28d', format: 'currency' },
    { name: 'Cost Per Offline Search', key: 'cost_per_offline_search', format: 'currency' },
    { name: 'Cost Per Offline View Content', key: 'cost_per_offline_view_content', format: 'currency' },
    { name: 'Cost Per Omni Content View', key: 'cost_per_omni_content_view', format: 'currency' },
    { name: 'Cost Per Omni On Site Lead', key: 'cost_per_omni_on_site_lead', format: 'currency' },
    { name: 'Cost Per On Site Content View', key: 'cost_per_on_site_content_view', format: 'currency' },
    { name: 'Cost Per On Site Lead', key: 'cost_per_on_site_lead', format: 'currency' },
    { name: 'Cost Per Outbound Click', alias: 'CPOC', key: 'cost_per_outbound_click', format: 'currency' },
    { name: 'Cost Per Unique Click', key: 'cost_per_unique_click', format: 'currency' },
    { name: 'Cost Per Unique Content View', key: 'cost_per_unique_content_view', format: 'currency' },
    { name: 'Cost Per Unique Form Lead', key: 'cost_per_unique_form_lead', format: 'currency' },
    { name: 'Cost Per Landing Page View', alias: 'CPLPV', key: 'cost_per_landing_page_view', format: 'currency' },
    { name: 'Cost Per Unique Omni Content View', key: 'cost_per_unique_omni_content_view', format: 'currency' },
    { name: 'Cost Per Unique On Site Content View', key: 'cost_per_unique_on_site_content_view', format: 'currency' },
    { name: 'Cost Per Unique Outbound Click', key: 'cost_per_unique_outbound_click', format: 'currency' },
    { name: 'Cost Per Unique Website Leads', key: 'cost_per_unique_website_leads', format: 'currency' },
    { name: 'Cost Per Website And Form Lead', key: 'cost_per_website_and_form_lead', format: 'currency' },
    { name: 'Cost Per Messaging Block', key: 'cost_per_onsite_conversion_messaging_block', format: 'currency' },
    { name: 'Cost Per Conversation Started 7d', key: 'cost_per_messaging_conversation_started_7d', format: 'currency' },
    { name: 'Cost Per Messaging First Reply', key: 'cost_per_messaging_first_reply', format: 'currency' },
    { name: 'Cost Per Website Lead', key: 'cost_per_website_lead', format: 'currency' },
    { name: 'Cost Per Mille', alias: 'CPM', key: 'cpm', format: 'currency' },
    { name: 'CPP', key: 'cpp', format: 'currency' },
    { name: 'Click Through Rate', alias: 'CTR', key: 'ctr', format: 'percent' },
    { name: 'Date Start', key: 'date_start', format: 'date' },
    { name: 'Date Stop', key: 'date_stop', format: 'date' },
    { name: 'Find Location', key: 'find_location', format: 'number' },
    { name: 'Form Lead Rate', key: 'form_lead_rate', format: 'percent' },
    { name: 'Form Leads', key: 'form_leads', format: 'number' },
    { name: 'Frequency', key: 'frequency', format: 'decimal' },
    { name: 'Impressions', key: 'impressions', format: 'number' },
    { name: 'Landing Page View Rate', key: 'landing_page_view_rate', format: 'percent' },
    { name: 'Landing Page Views', key: 'landing_page_views', format: 'number' },
    { name: 'Lead Rate', key: 'lead_rate', format: 'percent' },
    { name: 'Lead To Reach Rate', key: 'lead_to_reach_rate', format: 'percent' },
    { name: 'Lead To Unique Click Rate', key: 'lead_to_unique_click_rate', format: 'percent' },
    { name: 'Lead To Landing Page View Rate', key: 'lead_to_landing_page_view_rate', format: 'percent' },
    {
        name: 'Lead To Unique On Site View Content Rate',
        key: 'lead_to_unique_on_site_view_content_rate',
        format: 'percent'
    },
    { name: 'Leads', key: 'leads', format: 'number' },
    { name: 'Offline Add Payment Info', key: 'offline_add_payment_info', format: 'number' },
    { name: 'Offline Add Payment Info 1d Click', key: 'offline_add_payment_info_1d_click', format: 'number' },
    { name: 'Offline Add Payment Info 1d View', key: 'offline_add_payment_info_1d_view', format: 'number' },
    { name: 'Offline Add Payment Info 28d Click', key: 'offline_add_payment_info_28d_click', format: 'number' },
    { name: 'Offline Add Payment Info 28d View', key: 'offline_add_payment_info_28d_view', format: 'number' },
    { name: 'Offline Add Payment Info 7d Click', key: 'offline_add_payment_info_7d_click', format: 'number' },
    { name: 'Offline Add Payment Info 7d View', key: 'offline_add_payment_info_7d_view', format: 'number' },
    { name: 'Offline Add Payment Info Rate', key: 'offline_add_payment_info_rate', format: 'percent' },
    { name: 'Offline Add To Cart', key: 'offline_add_to_cart', format: 'number' },
    { name: 'Offline Add To Cart 1d Click', key: 'offline_add_to_cart_1d_click', format: 'number' },
    { name: 'Offline Add To Cart 1d View', key: 'offline_add_to_cart_1d_view', format: 'number' },
    { name: 'Offline Add To Cart 28d Click', key: 'offline_add_to_cart_28d_click', format: 'number' },
    { name: 'Offline Add To Cart 28d View', key: 'offline_add_to_cart_28d_view', format: 'number' },
    { name: 'Offline Add To Cart 7d Click', key: 'offline_add_to_cart_7d_click', format: 'number' },
    { name: 'Offline Add To Cart 7d View', key: 'offline_add_to_cart_7d_view', format: 'number' },
    { name: 'Offline Add To Cart Rate', key: 'offline_add_to_cart_rate', format: 'percent' },
    { name: 'Offline Add To Wish List', key: 'offline_add_to_wish_list', format: 'number' },
    { name: 'Offline Add To Wish List 1d Click', key: 'offline_add_to_wish_list_1d_click', format: 'number' },
    { name: 'Offline Add To Wish List 1d View', key: 'offline_add_to_wish_list_1d_view', format: 'number' },
    { name: 'Offline Add To Wish List 28d Click', key: 'offline_add_to_wish_list_28d_click', format: 'number' },
    { name: 'Offline Add To Wish List 28d View', key: 'offline_add_to_wish_list_28d_view', format: 'number' },
    { name: 'Offline Add To Wish List 7d Click', key: 'offline_add_to_wish_list_7d_click', format: 'number' },
    { name: 'Offline Add To Wish List 7d View', key: 'offline_add_to_wish_list_7d_view', format: 'number' },
    { name: 'Offline Add To Wish List Rate', key: 'offline_add_to_wish_list_rate', format: 'percent' },
    { name: 'Offline Initiate Checkout', key: 'offline_initiate_checkout', format: 'number' },
    { name: 'Offline Initiate Checkout 1d Click', key: 'offline_initiate_checkout_1d_click', format: 'number' },
    { name: 'Offline Initiate Checkout 1d View', key: 'offline_initiate_checkout_1d_view', format: 'number' },
    { name: 'Offline Initiate Checkout 28d Click', key: 'offline_initiate_checkout_28d_click', format: 'number' },
    { name: 'Offline Initiate Checkout 28d View', key: 'offline_initiate_checkout_28d_view', format: 'number' },
    { name: 'Offline Initiate Checkout 7d Click', key: 'offline_initiate_checkout_7d_click', format: 'number' },
    { name: 'Offline Initiate Checkout 7d View', key: 'offline_initiate_checkout_7d_view', format: 'number' },
    { name: 'Offline Initiate Checkout Rate', key: 'offline_initiate_checkout_rate', format: 'percent' },
    { name: 'Offline Lead', key: 'offline_lead', format: 'number' },
    { name: 'Offline Lead 1d Click', key: 'offline_lead_1d_click', format: 'number' },
    { name: 'Offline Lead 1d View', key: 'offline_lead_1d_view', format: 'number' },
    { name: 'Offline Lead 28d Click', key: 'offline_lead_28d_click', format: 'number' },
    { name: 'Offline Lead 28d View', key: 'offline_lead_28d_view', format: 'number' },
    { name: 'Offline Lead 7d Click', key: 'offline_lead_7d_click', format: 'number' },
    { name: 'Offline Lead 7d View', key: 'offline_lead_7d_view', format: 'number' },
    { name: 'Offline Lead Rate', key: 'offline_lead_rate', format: 'percent' },
    { name: 'Offline Other', key: 'offline_other', format: 'number' },
    { name: 'Offline Other 1d Click', key: 'offline_other_1d_click', format: 'number' },
    { name: 'Offline Other 1d View', key: 'offline_other_1d_view', format: 'number' },
    { name: 'Offline Other 28d Click', key: 'offline_other_28d_click', format: 'number' },
    { name: 'Offline Other 28d View', key: 'offline_other_28d_view', format: 'number' },
    { name: 'Offline Other 7d Click', key: 'offline_other_7d_click', format: 'number' },
    { name: 'Offline Other 7d View', key: 'offline_other_7d_view', format: 'number' },
    { name: 'Offline Other Rate', key: 'offline_other_rate', format: 'percent' },
    { name: 'Offline Purchase', key: 'offline_purchase', format: 'number' },
    { name: 'Offline Purchase 1d Click', key: 'offline_purchase_1d_click', format: 'number' },
    { name: 'Offline Purchase 1d View', key: 'offline_purchase_1d_view', format: 'number' },
    { name: 'Offline Purchase 28d Click', key: 'offline_purchase_28d_click', format: 'number' },
    { name: 'Offline Purchase 28d Rate', key: 'offline_purchase_28d_rate', format: 'percent' },
    { name: 'Offline Purchase 28d Total', key: 'offline_purchase_28d_total', format: 'number' },
    { name: 'Offline Purchase 28d View', key: 'offline_purchase_28d_view', format: 'number' },
    { name: 'Offline Purchase 7d Click', key: 'offline_purchase_7d_click', format: 'number' },
    { name: 'Offline Purchase 7d View', key: 'offline_purchase_7d_view', format: 'number' },
    { name: 'Offline Purchase Rate', key: 'offline_purchase_rate', format: 'percent' },
    { name: 'Offline Search', key: 'offline_search', format: 'number' },
    { name: 'Offline Search 1d Click', key: 'offline_search_1d_click', format: 'number' },
    { name: 'Offline Search 1d View', key: 'offline_search_1d_view', format: 'number' },
    { name: 'Offline Search 28d Click', key: 'offline_search_28d_click', format: 'number' },
    { name: 'Offline Search 28d View', key: 'offline_search_28d_view', format: 'number' },
    { name: 'Offline Search 7d Click', key: 'offline_search_7d_click', format: 'number' },
    { name: 'Offline Search 7d View', key: 'offline_search_7d_view', format: 'number' },
    { name: 'Offline Search Rate', key: 'offline_search_rate', format: 'percent' },
    { name: 'Offline View Content', key: 'offline_view_content', format: 'number' },
    { name: 'Offline View Content 1d Click', key: 'offline_view_content_1d_click', format: 'number' },
    { name: 'Offline View Content 1d View', key: 'offline_view_content_1d_view', format: 'number' },
    { name: 'Offline View Content 28d Click', key: 'offline_view_content_28d_click', format: 'number' },
    { name: 'Offline View Content 28d View', key: 'offline_view_content_28d_view', format: 'number' },
    { name: 'Offline View Content 7d Click', key: 'offline_view_content_7d_click', format: 'number' },
    { name: 'Offline View Content 7d View', key: 'offline_view_content_7d_view', format: 'number' },
    { name: 'Offline View Content Rate', key: 'offline_view_content_rate', format: 'percent' },
    { name: 'Omni Content View Rate', key: 'omni_content_view_rate', format: 'percent' },
    { name: 'Omni Content Views', key: 'omni_content_views', format: 'number' },
    { name: 'Omni On Site Lead Rate', key: 'omni_on_site_lead_rate', format: 'percent' },
    { name: 'Omni On Site Leads', key: 'omni_on_site_leads', format: 'number' },
    { name: 'On Site Content View Rate', key: 'on_site_content_view_rate', format: 'percent' },
    { name: 'On Site Content Views', key: 'on_site_content_views', format: 'number' },
    { name: 'On Site Lead Rate', key: 'on_site_lead_rate', format: 'percent' },
    { name: 'On Site Leads', key: 'on_site_leads', format: 'number' },
    { name: 'On Site Messaging Block', key: 'onsite_conversion_messaging_block', format: 'number' },
    { name: 'On Site Messaging Conversation Started 7d ', key: 'messaging_conversation_started_7d', format: 'number' },
    { name: 'On Site Messaging First Reply', key: 'messaging_first_reply', format: 'number' },
    { name: 'On Site Messaging Block Rate', key: 'onsite_conversion_messaging_block_rate', format: 'percent' },
    {
        name: 'On Site Messaging Conversation Started 7d Rate',
        key: 'messaging_conversation_started_7d_rate',
        format: 'number'
    },
    { name: 'On Site Messaging First Reply Rate', key: 'messaging_first_reply_rate', format: 'percent' },
    { name: 'Outbound Clicks', key: 'outbound_clicks', format: 'number' },
    { name: 'Outbound Ctr', key: 'outbound_ctr', format: 'number' },
    { name: 'Reach', key: 'reach', format: 'number' },
    { name: 'Spend', key: 'spend', format: 'currency' },
    { name: 'Unique Click Rate', key: 'unique_click_rate', format: 'percent' },
    { name: 'Unique Clicks', key: 'unique_clicks', format: 'number' },
    { name: 'Unique Content View Rate', key: 'unique_content_view_rate', format: 'percent' },
    { name: 'Unique Content Views', key: 'unique_content_views', format: 'number' },
    { name: 'Unique Form Lead Rate', key: 'unique_form_lead_rate', format: 'percent' },
    { name: 'Unique Form Leads', key: 'unique_form_leads', format: 'number' },
    { name: 'Unique Omni Content View Rate', key: 'unique_omni_content_view_rate', format: 'percent' },
    { name: 'Unique Omni Content Views', key: 'unique_omni_content_views', format: 'number' },
    { name: 'Unique On Site Content View Rate', key: 'unique_on_site_content_view_rate', format: 'percent' },
    { name: 'Unique On Site Content Views', key: 'unique_on_site_content_views', format: 'number' },
    { name: 'Unique Outbound Clicks', key: 'unique_outbound_clicks', format: 'number' },
    { name: 'Unique Outbound Ctr', key: 'unique_outbound_ctr', format: 'number' },
    { name: 'Unique Website Lead Rate', key: 'unique_website_lead_rate', format: 'percent' },
    { name: 'Unique Website Leads', key: 'unique_website_leads', format: 'number' },
    { name: 'Website And Form Lead Rate', key: 'website_and_form_lead_rate', format: 'percent' },
    { name: 'Website And Form Leads', key: 'website_and_form_leads', format: 'number' },
    { name: 'Website Lead Rate', key: 'website_lead_rate', format: 'percent' },
    { name: 'Website Leads', key: 'website_leads', format: 'number' },
    { name: 'Website Leads 1d Click', key: 'website_leads_1d_click', format: 'number' },
    { name: 'Website Leads 1d View', key: 'website_leads_1d_view', format: 'number' },
    { name: 'Website Leads 28d Click', key: 'website_leads_28d_click', format: 'number' },
    { name: 'Website Leads 28d View', key: 'website_leads_28d_view', format: 'number' },
    { name: 'Website Leads 7d Click', key: 'website_leads_7d_click', format: 'number' },
    { name: 'Website Leads 7d View', key: 'website_leads_7d_view', format: 'number' }
];

export default AdvertisingMetrics;
