<template>
    <div class="widget-selector">
        <div class="widgets">
            <button
                v-if="size !== 'half'"
                class="widget"
                :class="{selected: selected == 'Funnel'}"
                @click="$emit('selectWidget', { component: 'Funnel', dataType: 'Totals' })">
                <p class="icon">
                    <!-- eslint-disable-next-line -->
                    <svg height="87" viewBox="0 0 87 87" width="87" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd" transform="translate(3 3)"><rect height="81" rx="7" stroke="#fff" stroke-width="5" width="81"/><g fill="#fff"><path d="m24 22h34l-11.4363636 16h-11.1272728z"/><path d="m35 43h11l-1.8333333 16h-7.3333334z"/></g></g></svg>
                </p>
                <p class="label">
                    Funnel
                </p>
            </button>
            <button
                class="widget"
                :class="{selected: selected == 'Billboard'}"
                @click="$emit('selectWidget', { component: 'Billboard', dataType: 'Totals' })">
                <p class="icon">
                    <!-- eslint-disable-next-line -->
                    <svg height="87" viewBox="0 0 87 87" width="87" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><g stroke="#fff"><rect height="81" rx="7" stroke-width="5" width="81" x="3" y="3"/><path d="m54.5 31.5v23" stroke-linecap="square" stroke-width="2"/><path d="m32.5 31.5v23" stroke-linecap="square" stroke-width="2"/></g><path d="m16 38h12v10h-12z" fill="#fff"/><path d="m38 38h12v10h-12z" fill="#fff"/><path d="m60 38h12v10h-12z" fill="#fff"/></g></svg>
                </p>
                <p class="label">
                    Billboard
                </p>
            </button>
            <button
                class="widget"
                :class="{selected: selected == 'Graph'}"
                @click="$emit('selectWidget', { component:'Graph', dataType: 'Intervals' })">
                <p class="icon">
                    <!-- eslint-disable-next-line -->
                    <svg height="87" viewBox="0 0 87 87" width="87" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><rect height="81" rx="7" stroke="#fff" stroke-width="5" width="81" x="3" y="3"/><g transform="translate(20 24)"><path d="m2.97903442 34.6735479 14.33333338-20.2917039 14.3333333 13.900489 14.3333333-27.41522381" stroke="#fff" stroke-width="3"/><g fill="#fff" stroke="#00a2ea" stroke-width="2"><circle cx="32" cy="29" r="4"/><circle cx="3" cy="34" r="4"/><circle cx="17" cy="15" r="4"/><circle cx="45" cy="3" r="4"/></g></g></g></svg>
                </p>
                <p class="label">
                    Line
                </p>
            </button>
            <button
                v-if="tableAvailable"
                class="widget"
                :class="{selected: selected == 'Table'}"
                @click="$emit('selectWidget', { component: 'Table', dataType: 'Campaigns' })">
                <p class="icon">
                    <!-- eslint-disable-next-line -->
                    <svg height="87" viewBox="0 0 87 87" width="87" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><rect height="81" rx="7" stroke="#fff" stroke-width="5" width="81" x="3" y="3"/><path d="m19 53h9v7h-9z" fill="#fff"/><path d="m33.5 56.5h8" stroke="#fff" stroke-linecap="square" stroke-width="3"/><path d="m50.055556 56.5h16.888888" stroke="#fff" stroke-linecap="square" stroke-width="3"/><path d="m19 39h9v7h-9z" fill="#fff"/><path d="m33.5 42.5h8" stroke="#fff" stroke-linecap="square" stroke-width="3"/><path d="m50.055556 42.5h16.888888" stroke="#fff" stroke-linecap="square" stroke-width="3"/><path d="m19 25h9v7h-9z" fill="#fff"/><g stroke="#fff" stroke-linecap="square" stroke-width="3"><path d="m33.5 28.5h8"/><path d="m50.055556 28.5h16.888888"/></g></g></svg>
                </p>
                <p class="label">
                    Table
                </p>
            </button>
            <button
                class="widget"
                :class="{selected: selected === 'Mixed'}"
                @click="$emit('selectWidget', { component:'Mixed', dataType: 'Intervals' })">
                <p class="icon">
                    <img
                        src="/img/chart-mixed.png"
                        alt="chart-mixed">
                </p>
                <p class="label">
                    Mixed
                </p>
            </button>
            <button
                class="widget"
                :class="{selected: selected === 'Column'}"
                @click="$emit('selectWidget', { component:'Column', dataType: 'Intervals' })">
                <p class="icon">
                    <img
                        src="/img/chart-column.png"
                        alt="chart-column">
                </p>
                <p class="label">
                    Column
                </p>
            </button>
            <button
                class="widget"
                :class="{selected: selected === 'Bar'}"
                @click="$emit('selectWidget', { component:'Bar', dataType: 'Intervals' })">
                <p class="icon">
                    <img
                        src="/img/chart-bar.png"
                        alt="chart-bar">
                </p>
                <p class="label">
                    Bar
                </p>
            </button>
            <button
                v-if="donutAvailable"
                class="widget"
                :class="{selected: selected === 'Donut'}"
                @click="$emit('selectWidget', { component:'Donut', dataType: 'Campaigns' })">
                <p class="icon">
                    <img
                        src="/img/chart-donut.png"
                        alt="chart-donut">
                </p>
                <p class="label">
                    Donut
                </p>
            </button>
            <button
                class="widget"
                :class="{selected: selected === 'Custom'}"
                @click="$emit('selectWidget', { component:'Custom', dataType: 'Intervals' })">
                <p class="icon">
                    <img
                        src="/img/chart-custom.png"
                        alt="chart-custom">
                </p>
                <p class="label">
                    Custom
                </p>
            </button>
        </div>
        <template v-if="selected == 'Table'">
            <p
                v-if="size == 'half'"
                class="results-message">
                <b>NOTE:</b> This widget size is limited to show only 5 rows.
            </p>
            <p
                v-if="size == 'full'"
                class="results-message">
                <b>NOTE:</b> This widget size will show 10 rows per page.  Additional pages will be dynamically created if the number of rows exceed what can be displayed on 1 page.  You will only see the first page in the preview.
            </p>
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'WidgetSelector',
    props: {
        size: {
            // full, half, or quarter
            type: String,
            required: true
        },
        meta: {
            type: Object,
            required: true
        },
        selected: {
            type: String,
            default: ''
        }
    },
    computed: {
        ...mapGetters([
            'activePage',
        ]),
        tableAvailable() {
            return (this.size == 'full' || this.size == 'half')
            && this.meta.platform !== 'Pinterest';
        },
        donutAvailable() {
            // temporary fix while Pinterest is not available on the back-end
            return this.meta.platform !== 'Pinterest';
        }
    }
};
</script>

<style lang="scss" scoped>
.widgets {
    margin-top: 20px;
    .widget {
        margin-right: 20px;
        padding: 10px;
        border-radius: 10px;
        opacity: 0.25;
        svg {
            height: 40px;
            width: 40px;
        }
        rect {
            fill: #00a2ea;
        }
        &.selected {
            background-color: #96D8F5;
            opacity: 1;
        }
        &:hover {
            opacity: 0.75;
        }
        .icon {
            margin: 0;

        }
    }
    svg, img {
        height: 40px;
        width: 40px;
    }

}
.results-message {
    margin-top: 15px;
}
</style>