<template>
    <styled-slideout
        position="left"
        label="Pages"
        :active-tab="activeTab"
        :tabs="tabs"
        :value="value"
        :width="900"
        @setActiveTab="setActiveTab"
        @input="$emit('input', $event)">
        <template #preconfigured>
            <div class="tab-content">
                <h2>Pre-configured Pages</h2>
                <p>Below are pre-configured pages that can be added to your report template by selecting the plus icon next to each pre-configured page. Once the pages that you wish to add to your report template selected, click on the Add Page(s) button to add them to your report template. Pre-configured pages are not editable.</p>
                <ul class="page-types">
                    <li
                        v-for="(type, i) in pageTypes[activeTab]"
                        :key="i"
                        class="type">
                        <p
                            class="page-preview"
                            :class="type.meta.layout.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()" />
                        <div class="description">
                            <p class="type-title">
                                {{ type.title }}
                            </p>
                            <p class="type-description">
                                {{ type.description }}
                            </p>
                        </div>
                        <div class="quantity-selector">
                            <span
                                class="minus"
                                @click="type.quantity--">
                                <icon
                                    name="minus"
                                    size="20"
                                    color="blue" />
                            </span>
                            <span class="value">
                                {{ type.quantity }}
                            </span>
                            <span
                                class="plus"
                                @click="type.quantity++">
                                <icon
                                    name="plus"
                                    size="20"
                                    color="blue" />
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="action-container">
                <button
                    class="action"
                    @click="addPages">
                    Add Page(s)
                </button>
            </div>
        </template>
        <template #custom>
            <div class="tab-content">
                <h2>Design your own custom pages</h2>
                <p>Below are pre-defined page layouts that can be added to your report template that can be used to create a customized report for your Dealers by selecting the plus icon next to each page layout. You will have the ability to add a Funnel, Billboard, Graph and Table components to these pages and select the metrics that are used to populate these components directly on the page. Once the pages that you wish to add to your report template selected, click on the Add Page(s) button to add them to your report template.</p>
                <ul class="page-types">
                    <li
                        v-for="(type, i) in pageTypes[activeTab]"
                        :key="i"
                        class="type">
                        <p
                            class="page-preview"
                            :class="type.meta.layout.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()" />
                        <div class="description">
                            <p class="type-title">
                                {{ type.title }}
                            </p>
                            <p class="type-description">
                                {{ type.description }}
                            </p>
                        </div>
                        <div class="quantity-selector">
                            <span
                                class="minus"
                                @click="type.quantity--">
                                <icon
                                    name="minus"
                                    size="20"
                                    color="blue" />
                            </span>
                            <span class="value">
                                {{ type.quantity }}
                            </span>
                            <span
                                class="plus"
                                @click="type.quantity++">
                                <icon
                                    name="plus"
                                    size="20"
                                    color="blue" />
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="action-container">
                <button
                    class="action"
                    @click="addPages">
                    Add Page(s)
                </button>
            </div>
        </template>
    </styled-slideout>
</template>

<script>
import { mapActions } from 'vuex';
import StyledSlideout from '@/components/globals/StyledSlideout';
import Icon from '@/components/globals/Icon';
import pageTypes from './pageTypes';

export default {
    name: 'AddPageSlideout',
    components: {
        StyledSlideout,
        Icon
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
        pages: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            tabs: [
                { label: 'Pre-configured Pages', icon: 'page-preconfigured', slot: 'preconfigured' },
                { label: 'Custom Pages', icon: 'page-blank', slot: 'custom' },
            ],
            pageTypes,
            activeTab: 'preconfigured',
        };
    },
    computed: {
        selectedPages() {
            return this.pageTypes[this.activeTab]
                .filter(type => type.quantity > 0)
                .map(type => new Array(type.quantity).fill({
                    title: type.preconfigured ? type.title : 'Untitled Page',
                    preconfigured: type.preconfigured,
                    meta: type.meta,
                    slots: type.slots
                }))
                .flat(1);
        }
    },
    methods: {
        ...mapActions({
            add: 'addPages',
        }),
        setActiveTab(e) {
            this.activeTab = e;
        },
        addPages() {
            if(this.activeTab == 'preconfigured') {
                const hasFrontCover = this.pages.filter(page => page.meta.layout == 'FrontCover').length > 0;
                const hasBackCover = this.pages.filter(page => page.meta.layout == 'BackCover').length > 0;
                const selectedPages = this.selectedPages;
                if(selectedPages.filter(page => page.meta.layout == 'FrontCover').length > 0 && hasFrontCover) {
                    alert('Your report already contains a Front Cover page');
                    return;
                }
                if(selectedPages.filter(page => page.meta.layout == 'BackCover').length > 0 && hasBackCover) {
                    alert('Your report already contains a Back Cover page');
                    return;
                }
            }
            const pages = this.selectedPages
                // Fixes bug with multiple pages being linked via Observers
                .map(type => JSON.parse(JSON.stringify(type)));
            this.add(pages);
            this.$emit('input', false);
            this.resetQuantity();
        },
        resetQuantity() {
            this.pageTypes.custom.forEach((type,i) => {
                try {
                    this.pageTypes.custom[i].quantity = 0;
                } catch(error) {
                    console.error('Could not update quantity for type:', type);
                }
            });
            this.pageTypes.preconfigured.forEach((type,i) => {
                try {
                    this.pageTypes.preconfigured[i].quantity = 0;
                } catch(error) {
                    console.error('Could not update quantity for type:', type);
                }
            });
        }

    }
};
</script>

<style lang="scss" scoped>
.tab-content {
    position: relative;
    padding: 20px 40px;
    overflow-y: scroll;
    padding-bottom: 80px;
}
.page-types {
    list-style: none;
    padding: 0;
    .type {
        margin-bottom: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .description {
        padding: 10px 30px;
        flex: 1;
        width: 50%;
    }
    .type-title {
        font-size: 14px;
        font-weight: 600;
        margin: 0;
        color: $blue-primary;
    }
    .type-description {
        max-width: 500px;
    }
}
.page-preview {
    margin: 0;
    background: $white;
    height: 80px;
    width: 110px;
    border: 1px solid #8F9EA6;
    background: url('./assets/layouts/full.svg') center center no-repeat;

    &.four-up {
        background: url('./assets/layouts/four-up.svg') center center no-repeat;
    }
    &.one-two {
        background: url('./assets/layouts/one-two.svg') center center no-repeat;
    }
    &.two-one {
        background: url('./assets/layouts/two-one.svg') center center no-repeat;
    }
    &.two-up {
        background: url('./assets/layouts/two-up.svg') center center no-repeat;
    }
}
.quantity-selector {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 200px;
    .minus,
    .plus {
        cursor: pointer;
    }
    .value {
        margin: 0 25px;
        font-weight: 700;
        font-size: 18px;
    }
}

.action-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 80px;
    width: 100%;
    background: #F7F8FC;
    box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.1);
    .action {
        background-color: darken($blue-primary,10%);
        padding: 8px 15px;
        border-radius: 5px;
        color: $white;
        font-weight: 600;
        text-transform: uppercase;
        transition: background-color 0.3s ease-out;

        &:hover {
            background-color: $blue-primary;
        }
    }
}
</style>