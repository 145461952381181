<template>
    <styled-slideout
        position="right"
        :width="650"
        :value="value"
        @input="$emit('input', $event)">
        <div
            ref="settings"
            class="settings">
            <h2>Page Settings</h2>
            <template v-if="page.preconfigured == true">
                <p class="preconfigured">
                    This page has been pre-configured and cannot be edited.
                </p>
            </template>
            <template v-else>
                <v-text-field
                    :value="page.title"
                    label="Page Title"
                    @input="updatePage({ title: $event })" />
                <div
                    v-if="pageHasTextFields"
                    class="text-fields">
                    <template v-for="(slot,i) in page.slots">
                        <v-text-field
                            v-if="slot.value"
                            :key="i"
                            :value="slot.value"
                            :label="slot.name.slice(0,1).toUpperCase() + slot.name.slice(1)"
                            @input="updateSlotTextField(i, $event)" />
                    </template>
                </div>
            </template>
            <div
                v-if="pageHasWidgets && !page.preconfigured"
                class="regions">
                <div
                    v-for="(slot,i) in page.slots"
                    :key="i"
                    ref="regions">
                    <div
                        :key="'region-'+(i+1)"
                        class="region">
                        <div class="region-meta">
                            <widget-region
                                :layout="page.meta.layout"
                                :region="i" />
                            <div class="region-title">
                                <v-text-field
                                    :value="slot.title"
                                    :label="`Region ${i+1} Title`"
                                    @input="updateSlotTitle(i, $event)" />
                            </div>
                        </div>
                        <widget-selector
                            :size="slot.size"
                            :meta="page.meta"
                            :selected="slot.component"
                            @selectWidget="updateSlotWidget(i, $event)" />

                        <data-selector
                            :widget="slot"
                            :widget-index="i"
                            @metricSelected="updateSlotMetrics(i, $event)" />
                    </div>
                </div>
            </div>
        </div>
    </styled-slideout>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import StyledSlideout from '@/components/globals/StyledSlideout';
import WidgetRegion from './widgets/WidgetRegion';
import WidgetSelector from './widgets/WidgetSelector';
import DataSelector from './DataSelector';

export default {
    name: 'PageSettingsSlideout',
    components: {
        StyledSlideout,
        WidgetRegion,
        WidgetSelector,
        DataSelector,
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
        regionIndex: {
            type: Number,
            default: 0
        }
    },
    watch: {
        async value() {
            if (this.value === true) {
                await this.$nextTick();
                if (this.regionIndex) {
                    const currentRegion = this.$refs.regions[this.regionIndex];
                    const settings = this.$refs.settings;
                    settings.parentElement.scrollTo({
                        top: currentRegion.offsetTop,
                        behavior: 'smooth'
                    });
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            page: 'activePage',
        }),
        ...mapState({
            metrics: state => state.metrics
        }),
        pageHasTextFields() {
            return this.page.slots.some(s => s.type == 'text');
        },
        pageHasWidgets() {
            return this.page.slots.some(s => s.type == 'widget');
        }
    },
    methods: {
        ...mapActions([
            'updatePage',
            'updatePageMeta',
            'updateWidget',
        ]),
        updateSlotTitle(slotIndex, title) {
            this.updateWidget({ slotIndex, title });
        },
        updateSlotTextField(slotIndex, value) {
            this.updateWidget({ slotIndex, value });
        },
        updateSlotWidget(slotIndex, { component, dataType }) {
            let metrics = this.page.slots[slotIndex].metrics.slice(0,1);
            const hasStackMetrics = this.page.slots[slotIndex]?.stackMetrics ?? false;
            if (hasStackMetrics) { this.page.slots[slotIndex].stackMetrics = false }
            this.updateWidget({ slotIndex, component, dataType, metrics });
        },
        updateSlotMetrics(slotIndex, metrics) {
            this.updateWidget({ slotIndex, metrics });
        }

    }
};
</script>

<style lang="scss">
.settings {
    .builder-autocomplete {
        background: $white;
        border: 1px solid #CED4D7;
        padding: 5px;
        .v-label--active {
            background: $white;
            padding: 0 4px;
        }
        .v-input__slot {
            margin: 0;
            &:before {
                display: none;
            }
        }
        .v-text-field__details {
            display: none;
        }
    }
}
</style>

<style lang="scss" scoped>
.settings {
    flex: 1 auto;
    padding: 20px 40px 80px;
    overflow-y: scroll;
}
.preconfigured {
    text-align: center;
    background: $gray-light;
    border: 1px solid $black;
    padding: 30px 15px;
}
.meta.builder-autocomplete {
    margin: 0 0 20px 15px;
}
h2 {
    margin-bottom: 20px;
}
.regions {
    display: flex;
    flex-direction: column;
    div:nth-of-type(odd) {
        .region {
            background-color: #D8F0FB;
        }
    }
}
.region {
    padding: 30px;
    margin: 0;
    margin: 0 -40px;
}
.region-meta {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    .region-title {
        margin-left: 30px;
        display: flex;
        align-items: center;
    }
}
</style>