<template>
    <div class="data-selector">
        <template v-if="widget.component === 'Custom'">
            <p class="section-label">
                Select the type of content
            </p>
            <v-radio-group
                v-model="showImage"
                class="report-builder-radio pl-4"
                row>
                <v-radio
                    label="Image"
                    :value="true"
                    class="radio-bg-white"
                    color="#00A3EA" />
                <v-radio
                    label="Text Editor"
                    :value="false"
                    class="radio-bg-white"
                    color="#00A3EA" />
            </v-radio-group>
            <div v-show="!showImage">
                <p class="section-label">
                    Media
                </p>
                <v-select
                    v-model="editorIcon"
                    class="builder-select mb-4"
                    label="ICON"
                    :items="textIcons" />
                <p class="section-label">
                    Typography
                </p>
                <vue-editor
                    v-model="editorContent"
                    :editor-toolbar="customToolbar" />
            </div>
            <div v-show="showImage">
                <loader v-if="imageLoading" />
                <div
                    v-show="!imageLoading"
                    class="image-wrapper">
                    <div
                        v-if="imageUrl"
                        class="image-show">
                        <img :src="imageUrl">
                        <div class="image-delete">
                            <span @click="deleteImage">Delete</span>
                        </div>
                    </div>
                    <div
                        class="image-uploader"
                        :class="{'with-image': imageUrl}">
                        <asset-upload
                            :key="assetKey"
                            :initial-image="null"
                            :rounded="true"
                            :types="['jpeg', 'jpg', 'png', 'gif']"
                            :file-limit="true"
                            @loading-started="imageLoading = true"
                            @loading-ended="imageLoading = false"
                            @asset-uploaded-url="onImageUpload" />
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <p class="section-label">
                Data Source
            </p>
            <div class="region-channel">
                <v-autocomplete
                    :value="widget.channel"
                    label="Platform"
                    :items="availablePlatforms"
                    class="meta builder-autocomplete"
                    @input="setPlatform($event, widget.name)" />
                <div
                    v-if="widget.component !== 'Custom'"
                    class="region-icon">
                    <styled-checkbox
                        :square="true"
                        :checked="widget.showIcon"
                        @change="updateIcon($event)" />
                    <span class="region-icon-text">add icon</span>
                </div>
            </div>
            <template v-if="widget.component == 'Table' || widget.component == 'Donut'">
                <p class="section-label">
                    Data Type
                </p>
                <v-autocomplete
                    class="type builder-autocomplete"
                    label="Data Type"
                    :items="dataSources"
                    :value="widget.dataType"
                    @input="setDataType" />
                <template v-if="widget.component == 'Donut'">
                    <p class="section-label-points">
                        # of Data Points to Analyze <span>(For best visual results we recommend to keep between 3 and 6)</span>
                    </p>
                    <div class="points-selector">
                        <span
                            class="points-icon"
                            @click="minusPoints">
                            <icon
                                color="#00A2EA"
                                name="minus"
                                size="20" />
                        </span>
                        <span class="points-num">{{ widgetDataPoints }}</span>
                        <span
                            class="points-icon"
                            @click="addPoints">
                            <icon
                                color="#00A2EA"
                                name="indicator-add"
                                size="20" />
                        </span>
                    </div>
                </template>
            </template>
            <template v-if="widget.component == 'Table'">
                <template v-if="widget.size == 'full'">
                    <p class="section-label">
                        Pagination
                    </p>
                    <v-select
                        class="pagination builder-select"
                        label="Pages to Show"
                        :items="['All', 1, 2, 3]"
                        value="All"
                        @change="setLastPage" />
                </template>

                <p class="section-label">
                    Sorting
                </p>
                <v-autocomplete
                    class="sorting builder-autocomplete"
                    label="Sort By"
                    :items="sortableMetrics"
                    :value="widget.sortBy ? widget.sortBy.metric : null"
                    item-value="key"
                    item-text="name"
                    @input="setSortByMetric" />
                <v-autocomplete
                    class="sorting builder-autocomplete"
                    label="Sort Direction"
                    :items="[{name: 'Lowest Value First', value: 'asc'},{name: 'Highest Value First', value: 'desc'}]"
                    :value="widget.sortBy ? widget.sortBy.direction : null"
                    item-value="value"
                    item-text="name"
                    @input="setSortByDirection" />
            </template>

            <p class="section-label">
                Metrics
            </p>
            <v-switch
                v-if="widget.component == 'Table'"
                v-model="stackMetrics"
                label="Stack Metrics"
                color="light-blue" />
            <div
                v-for="(x,i) in metricCount"
                :key="i"
                class="data-metric-container"
                :class="{stacked: stackMetrics}">
                <v-autocomplete
                    v-model="selectedMetrics[i]"
                    class="data-metric builder-autocomplete"
                    :label="`Metric ${i+1}`"
                    :items="filteredMetrics"
                    item-value="key"
                    item-text="name"
                    @input="$emit('metricSelected', selectedMetrics)" />
                <button
                    v-if="i > 0"
                    class="remove-metric-button"
                    @click="removeMetric(i)">
                    <icon
                        size="15"
                        name="close"
                        color="red" />
                </button>
            </div>
            <p
                v-if="metricsMissing"
                class="error-message">
                You must select at least {{ minMetricCount }} metrics for this widget.
            </p>
            <p
                v-if="error"
                class="error-message">
                {{ error }}
            </p>
            <button
                class="add-metric-button"
                @click="addMetric">
                <icon
                    class="icon"
                    name="plus"
                    size="20"
                    color="blue" /><p class="text">
                        Add Metric
                    </p>
            </button>
        </template>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Icon from '@/components/globals/Icon';
import FacebookAdvertisingMetrics from './mapping/facebook/AdvertisingMetrics';
import SnapchatAdvertisingMetrics from './mapping/snapchat/AdvertisingMetrics';
import TiktokAdvertisingMetrics from './mapping/tiktok/AdvertisingMetrics';
import PinterestAdvertisingMetrics from './mapping/pinterest/AdvertisingMetrics';
import { PLATFORM_FACEBOOK, PLATFORM_TIKTOK, PLATFORM_SNAPCHAT, PLATFORM_PINTEREST } from '@/helpers/globals';
import { capitalizeWord } from '@/helpers/capitalizeWord';
import StyledCheckbox from '@/components/globals/StyledCheckbox';
import { VueEditor } from 'vue2-editor';
import AssetUpload from '@/components/globals/AssetUpload';
import Loader from '@/components/globals/Loader';

export default {
    name: 'DataSelector',
    components: {
        StyledCheckbox,
        Icon,
        VueEditor,
        AssetUpload,
        Loader
    },
    props: {
        widget: {
            type: Object,
            required: true
        },
        widgetIndex: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            content: '',
            customToolbar: [
                [{ header: [false, 1, 2 ] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ align: '' }, { align: 'center' }, { align: 'right' }],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ color: [] }, { background: [] }],
            ],
            textIcons: ['None', 'Light Bulb', 'Lens', 'Trophy'],
            textIcon: 'None',
            imageLoading: false,
            assetKey: 1,
            metricCount: 1,
            error: '',
            selectedMetrics: [],
            dataPoints: 3,
            metricCounts: {
                quarter: {
                    Billboard: { min: 1, max:6 },
                    Funnel: { min: 3, max:3 },
                    Graph: { min: 1, max:2 },
                    Mixed: { min: 1, max:2 },
                    Column: { min: 1, max:2 },
                    Bar: { min: 1, max:4 },
                    Donut: { min: 1, max:1 },
                },
                half: {
                    Billboard: { min: 1, max:12 },
                    Graph: { min: 1, max:2 },
                    Table: { min: 1, max:10 },
                    Mixed: { min: 1, max:2 },
                    Column: { min: 1, max:2 },
                    Bar: { min: 1, max:4 },
                    Donut: { min: 1, max:1 },
                },
                full: {
                    Billboard: { min: 1, max:24 },
                    Graph: { min: 1, max:2 },
                    Table: { min: 1, max:10 },
                    Funnel: { min: 7, max:7 },
                    Mixed: { min: 1, max:2 },
                    Column: { min: 1, max:2 },
                    Bar: { min: 1, max:8 },
                    Donut: { min: 1, max:1 },
                }
            }
        };
    },
    computed: {
        ...mapGetters([
            'activePage'
        ]),
        availablePlatforms() {
            return [
                capitalizeWord(PLATFORM_FACEBOOK),
                capitalizeWord(PLATFORM_SNAPCHAT),
                capitalizeWord(PLATFORM_TIKTOK),
                capitalizeWord(PLATFORM_PINTEREST)
            ];
        },
        currentSlot() {
            return this.activePage.slots[this.widgetIndex];
        },
        dataSources() {
            if (this.widget.component === 'Donut') {
                return ['Campaigns'];
            }
            return ['Campaigns', 'Inventory'];
        },
        minMetricCount() {
            return this.metricCounts[this.widget.size][this.widget.component]?.min ?? 1;
        },
        maxMetricCount() {
            const max = this.metricCounts[this.widget.size][this.widget.component].max;
            return this.stackMetrics ? max * 2 : max;
        },
        stackMetrics: {
            set(value) {
                this.setStackMetrics(value);
            },
            get() {
                return this.widget?.stackMetrics || false;
            }
        },
        widgetDataPoints() {
            return this.currentSlot?.dataPoints || this.dataPoints;
        },
        editorContent: {
            set(value) {
                this.setContent(value);
            },
            get() {
                return this.currentSlot?.content || this.content;
            }
        },
        editorIcon: {
            set(value) {
                this.setIcon(value);
            },
            get() {
                return this.currentSlot?.textIcon || this.textIcon;
            }
        },
        imageUrl() {
            return this.currentSlot?.image || '';
        },
        showImage: {
            set(value) {
                this.setShowingImage(value);
            },
            get() {
                return this.currentSlot?.showImage;
            }
        },
        availableMetrics() {
            switch (this.currentSlot.channel) {
                case capitalizeWord(PLATFORM_FACEBOOK): return FacebookAdvertisingMetrics;
                case capitalizeWord(PLATFORM_SNAPCHAT): return SnapchatAdvertisingMetrics;
                case capitalizeWord(PLATFORM_TIKTOK): return TiktokAdvertisingMetrics;
                case capitalizeWord(PLATFORM_PINTEREST): return PinterestAdvertisingMetrics;
                default: return [];
            }
        },
        filteredMetrics() {
            return this.availableMetrics.map(m => {
                const disabledMetric = this.widget.component === 'Donut' && m.format === 'currency' && m.key !== 'spend';
                return { ...m, disabled: (this.selectedMetrics.indexOf(m.key) > -1) || disabledMetric };
            });
        },
        sortableMetrics() {
            return this.availableMetrics.filter(m => this.selectedMetrics.includes(m.key));
        },
        metricsMissing() {
            return this.selectedMetrics.length < this.minMetricCount;
        }
    },
    watch: {
        'widget.component'() {
            this.metricCount = 1;
            this.selectedMetrics = this.selectedMetrics.slice(0,1);
            this.$emit('metricSelected', this.selectedMetrics);
        },
        'widget.metrics'(metrics) {
            this.selectedMetrics = metrics;
        }
    },
    created() {
        if(this.widget.metrics.length) {
            this.metricCount = this.widget.metrics.length;
            this.selectedMetrics = this.widget.metrics;
        }
        this.dataPoints = this.widgetDataPoints;
    },
    methods: {
        ...mapActions([
            'updateWidget',
            'updatePage'
        ]),
        setPlatform(channel) {
            this.updateWidget({ slotIndex: this.widgetIndex, channel });
        },
        setDataType(type) {
            this.updateWidget({ slotIndex: this.widgetIndex, dataType: type });
        },
        updateIcon(event) {
            const showIcon = event.target.checked;
            if (event.target.checked) {
                this.updateWidget({ slotIndex: this.widgetIndex, showIcon });
            } else {
                this.updateWidget({ slotIndex: this.widgetIndex, showIcon });
            }
        },
        setDataPoints() {
            const slots = JSON.parse(JSON.stringify(this.activePage.slots));
            slots[this.widgetIndex].dataPoints = this.dataPoints;
            this.updatePage({ slots });
        },
        setContent(content) {
            const slots = JSON.parse(JSON.stringify(this.activePage.slots));
            slots[this.widgetIndex].content = content;
            this.updatePage({ slots });
        },
        setIcon(icon) {
            const slots = JSON.parse(JSON.stringify(this.activePage.slots));
            slots[this.widgetIndex].textIcon = icon;
            this.updatePage({ slots });
        },
        setShowingImage(value) {
            const slots = JSON.parse(JSON.stringify(this.activePage.slots));
            slots[this.widgetIndex].showImage = value;
            this.updatePage({ slots });
        },
        onImageUpload(image) {
            const slots = JSON.parse(JSON.stringify(this.activePage.slots));
            slots[this.widgetIndex].image = image;
            this.updatePage({ slots });
        },
        deleteImage() {
            const slots = JSON.parse(JSON.stringify(this.activePage.slots));
            slots[this.widgetIndex].image = '';
            this.updatePage({ slots });
            this.assetKey += 1;
        },
        minusPoints() {
            if (this.dataPoints === 1) { return }
            this.dataPoints -= 1;
            this.setDataPoints();
        },
        addPoints() {
            if (this.dataPoints === 10) { return }
            this.dataPoints += 1;
            this.setDataPoints();
        },
        setLastPage(lastPage) {
            console.log('Last:', lastPage);
            let slots = JSON.parse(JSON.stringify(this.activePage.slots));
            let index = slots.findIndex(slot => slot.component == 'Table');
            if(!(slots[index].pagination instanceof Object)) {
                slots[index].pagination = { lastPage };
            } else {
                slots[index].pagination.lastPage = lastPage;
            }
            this.updatePage({ slots });
        },
        setSortByMetric(metric) {
            let slots = JSON.parse(JSON.stringify(this.activePage.slots));
            let index = slots.findIndex(slot => slot.component == 'Table');
            if(!(slots[index].sortBy instanceof Object)) {
                slots[index].sortBy = { metric };
            } else {
                slots[index].sortBy.metric = metric;
            }
            this.updatePage({ slots });
        },
        setSortByDirection(direction) {
            let slots = JSON.parse(JSON.stringify(this.activePage.slots));
            let index = slots.findIndex(slot => slot.component == 'Table');
            if(!(slots[index].sortBy instanceof Object)) {
                slots[index].sortBy = { direction };
            } else {
                slots[index].sortBy.direction = direction;
            }
            this.updatePage({ slots });
        },
        setStackMetrics(value) {
            let slots = JSON.parse(JSON.stringify(this.activePage.slots));
            let index = slots.findIndex(slot => slot.component == 'Table');
            slots[index].stackMetrics = value;
            this.updatePage({ slots });
        },
        addMetric() {
            if(this.metricCount < this.maxMetricCount) {
                this.metricCount++;
            } else {
                this.error = 'You\'ve reached the maximum number of metrics allowed for this configuration';
                setTimeout(() => this.error = '', 3000);
            }
        },
        removeMetric(index) {
            this.selectedMetrics.splice(index,1);
            this.metricCount = this.metricCount -1;
        }
    }
};
</script>
<style lang="scss">
.data-selector {
    .section-label {
        font-weight: 700;
    }
    .sorting,
    .type {
        margin-bottom: 15px;
    }
    .pagination {
        margin-bottom: 15px;
        width: 120px;
    }
    .builder-select,
    .builder-autocomplete {
        background: $white;
        border: 1px solid #CED4D7;
        padding: 5px;
        .v-label--active {
            text-transform: uppercase;
            background: $white;
            padding: 0 4px;
        }
        .v-input__slot {
            margin: 0;
            &:before {
                display: none;
            }
        }
        .v-select__selection {
            color: $black !important;
        }
        .v-text-field__details {
            display: none;
        }
    }
}
.region-channel {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}
.region-icon {
    margin-left: 30px;
    display: flex;
}
.region-icon-text {
    margin-left: 10px;
}
.quillWrapper {
    background-color: white;
}
#quill-container {
    h1, h2 {
        color: #4e555b;
    }
}
.image-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    margin-top: -30px;
}
.image-uploader {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    &.with-image {
        width: calc(100% - 200px);
    }
}
.image-show {
    margin-top: 35px;
    width: 200px;
    max-height: 150px;
    text-align: center;
    box-sizing: border-box;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    img {
        max-width: 100%;
        max-height: 150px;
        height: auto;
    }
}
.image-delete {
    text-align: center;
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    right: 0;
    cursor: pointer;
    color: $blue-bg;
    text-decoration: underline;
}
</style>

<style lang="scss" scoped>
.image-uploader > div {
    background-color: white;
    border-radius: 15px;
}
.data-selector {
    margin-top: 30px;
}
.data-metric-container {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
    &.stacked:nth-of-type(2n) {
        &:before {
            display: block;
            position: absolute;
            top: -30px;
            content: 'Metric Stack';
        }
    }
    &.stacked:nth-of-type(2n+1) {
        margin-top: 5px;
        margin-bottom: 40px;
    }
}
.remove-metric-button {
    margin-left: 5px;
}
.add-metric-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    margin-top: 20px;
    width: 100%;
    border: 1px solid #03A2EA;
    border-radius: 4px;
    font-size: 13px;
    text-transform: uppercase;
    background: $white;
    .text {
        margin-left: 5px;
    }
}
.error-message {
    padding: 5px;
    text-align: center;
    color: #F52433;
}
.section-label-points {
    font-weight: bold;
    letter-spacing: -0.3px;
    margin-top: 30px;
    span {
        font-weight: 400
    }
}
.points-selector {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    margin-top: -10px;
}
.points-icon {
    cursor: pointer;
    display: block;
}
.points-num {
    font-size: 20px;
    font-weight: bold;
    margin-right: 20px;
    margin-left: 20px;
}
</style>