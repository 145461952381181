//Pre-configured pages
const preconfigured = [
    {
        title: 'Front Cover',
        description: 'Adds in a pre-configured Front Cover page that will include the Template Name, Dealer, Date Range and Agency name to your report template.',
        quantity: 0,
        preconfigured: true,
        meta: {
            branded: false,
            layout: 'FrontCover',
            background: 'connecting-nodes',
            required_feature: null
        },
        slots: [
            { name: 'title', type: 'text', eval: 'brand.template_name' },
            { name: 'account', type: 'text', eval: 'dealer.name' },
            { name: 'date-range', type: 'text', eval: 'dateRangeFormatted' },
            { name: 'logo',type: 'image', eval: 'brand.logo' }
        ]

    },
    {
        title: 'Meta Advertising Funnel',
        description: 'Adds in the pre-configured page that includes the funnel that is used on the Meta Advertising page.',
        quantity: 0,
        preconfigured: true,
        action: {
            text: 'View Dashboard',
            url: '<<dealer_url>>/dashboard'
        },
        meta: {
            branded: true,
            layout: 'Full',
            platform: 'Facebook',
            product: 'Advertising',
            required_feature: 'facebook_ad_account'
        },
        slots: [
            {
                name: 'slot1',
                title: '',
                type: 'widget',
                size: 'full',
                component: 'AdvertisingFunnel',
                dataType: 'Totals',
                metrics: [
                    'reach',
                    'spend',
                    'unique_click_rate',
                    'lead_to_unique_click_rate',
                    'offline_purchase_28d_rate',
                    'unique_clicks',
                    'leads',
                    'offline_purchase_28d_total',
                    'cost_per_unique_click',
                    'cost_per_lead',
                    'cost_per_offline_purchase_28d',
                    'reach',
                    'spend',
                    'unique_click_rate',
                    'lead_to_unique_click_rate',
                    'unique_clicks',
                    'leads',
                    'cost_per_unique_click',
                    'cost_per_lead'
                ]
            }
        ]
    },
    {
        title: 'Offline Events Matchback',
        description: 'Adds in a pre-configured page that includes a matchback report of attributed offline sales for the reporting period.',
        quantity: 0,
        preconfigured: true,
        meta: {
            branded: true,
            layout: 'Full',
            platform: 'Facebook',
            product: 'Advertising',
            required_feature: null
        },
        slots: [
            {
                name: 'slot1',
                title: '',
                type: 'widget',
                size: 'full',
                component: 'OfflineEventsMatchback',
                dataType: '',
                extended_info: false,
                metrics: []
            }
        ]
    },
    {
        title: 'Offline Events Matchback with Cost Per Unit Sold',
        description: 'Adds in a pre-configured page that includes a matchback report of attributed offline sales for the reporting period and contains the Cost Per Unit Sold (Cost per Offline Purchase) metric',
        quantity: 0,
        preconfigured: true,
        meta: {
            branded: true,
            layout: 'Full',
            platform: 'Facebook',
            product: 'Advertising',
            required_feature: null
        },
        slots: [
            {
                name: 'slot1',
                title: '',
                type: 'widget',
                size: 'full',
                component: 'OfflineEventsMatchback',
                dataType: '',
                extended_info: true,
                metrics: []
            }
        ]
    },
    {
        title: 'Back Cover',
        description: 'Adds in a pre-configured Back cover page to your report template that displays your Agency name and a link to your website.',
        quantity: 0,
        preconfigured: true,
        meta: {
            branded: false,
            layout: 'BackCover',
            background: 'connecting-nodes',
            required_feature: null
        },
        slots: [
            { name: 'logo',type: 'image', eval: 'brand.logo' },
            { name: 'url', type: 'text', eval: 'brand.app_url' }
        ]

    },
];

// Custom pages
const custom = [
    {
        title: 'Full Page',
        description: 'Creates a new page on your report template that will allow 1 component with custom metrics to be used.',
        quantity: 0,
        preconfigured: false,
        meta: {
            branded: true,
            layout: 'Full',
            platform: '',
            product: '',
        },
        slots: [
            {
                name: 'slot1',
                title: '',
                type: 'widget',
                size: 'full',
                component: '',
                dataType: '',
                showIcon: false,
                showImage: false,
                metrics: []
            }
        ]
    },
    {
        title: '2 up Horizontal',
        description: 'Creates a new page on your report template that will include 2 custom components. The page will format the components horizontally with one component on the top and another below it.',
        quantity: 0,
        preconfigured: false,
        meta: {
            branded: true,
            layout: 'TwoUp',
            platform: '',
            product: '',
        },
        slots: [
            {
                name: 'slot1',
                title: '',
                type: 'widget',
                size: 'half',
                component: '',
                dataType: '',
                showIcon: false,
                showImage: false,
                metrics: []
            },
            {
                name: 'slot2',
                title: '',
                type: 'widget',
                size: 'half',
                component: '',
                dataType: '',
                showIcon: false,
                showImage: false,
                metrics: []
            }
        ]
    },
    {
        title: '3 up (1 top, 2 bottom)',
        description: 'Creates a new page on your report template that will include 3 components. One component will stretch the top half of the page while the other two will split the lower half of the page.',
        quantity: 0,
        preconfigured: false,
        meta: {
            branded: true,
            layout: 'OneTwo',
            platform: '',
            product: '',
        },
        slots: [
            {
                name: 'slot1',
                title: '',
                type: 'widget',
                size: 'half',
                component: '',
                dataType: '',
                showIcon: false,
                showImage: false,
                metrics: []
            },
            {
                name: 'slot2',
                title: '',
                type: 'widget',
                size: 'quarter',
                component: '',
                dataType: '',
                showIcon: false,
                showImage: false,
                metrics: []
            },
            {
                name: 'slot3',
                title: '',
                type: 'widget',
                size: 'quarter',
                component: '',
                dataType: '',
                showIcon: false,
                showImage: false,
                metrics: []
            }
        ]
    },
    {
        title: '3 up (2 top, 1 bottom)',
        description: 'Creates a new page on your report template that will include 3 components. Two components will split the top half of the page while the other will stretch the lower half of the page.',
        quantity: 0,
        preconfigured: false,
        meta: {
            branded: true,
            layout: 'TwoOne',
            platform: '',
            product: '',
        },
        slots: [
            {
                name: 'slot1',
                title: '',
                type: 'widget',
                size: 'quarter',
                component: '',
                dataType: '',
                showIcon: false,
                showImage: false,
                metrics: []
            },
            {
                name: 'slot2',
                title: '',
                type: 'widget',
                size: 'quarter',
                component: '',
                dataType: '',
                showIcon: false,
                showImage: false,
                metrics: []
            },
            {
                name: 'slot3',
                title: '',
                type: 'widget',
                size: 'half',
                component: '',
                dataType: '',
                showIcon: false,
                showImage: false,
                metrics: []
            }
        ]
    },
    {
        title: '4 up',
        description: 'Creates a new page on your report template that will include 4 components. Two components will split the top half of the page and the other two will split the lower half of the page.',
        quantity: 0,
        preconfigured: false,
        meta: {
            branded: true,
            layout: 'FourUp',
            platform: '',
            product: '',
        },
        slots: [
            {
                name: 'slot1',
                title: '',
                type: 'widget',
                size: 'quarter',
                component: '',
                dataType: '',
                showIcon: false,
                showImage: false,
                metrics: []
            },
            {
                name: 'slot2',
                title: '',
                type: 'widget',
                size: 'quarter',
                component: '',
                dataType: '',
                showIcon: false,
                showImage: false,
                metrics: []
            },
            {
                name: 'slot3',
                title: '',
                type: 'widget',
                size: 'quarter',
                component: '',
                dataType: '',
                showIcon: false,
                showImage: false,
                metrics: []
            },
            {
                name: 'slot4',
                title: '',
                type: 'widget',
                size: 'quarter',
                component: '',
                dataType: '',
                showIcon: false,
                showImage: false,
                metrics: []
            }
        ]
    },
];

export default {
    custom,
    preconfigured
};