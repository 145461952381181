// New metrics should be added in alphabetical order
const AdvertisingMetrics = [
    { name: 'Add To Cart Web Event', key: 'web_event_add_to_cart', format: 'number' },
    { name: 'Add To Cart Web Event Rate', key: 'web_event_add_to_cart_rate', format: 'percent' },
    { name: 'Add to Wishlist On Web', key: 'on_web_add_to_wishlist', format: 'number' },
    { name: 'Add to Wishlist On Web Rate', key: 'on_web_add_to_wishlist_rate', format: 'percent' },
    { name: 'Browse Product Details Page', key: 'product_details_page_browse', format: 'number' },
    { name: 'Browse Product Details Page Rate', key: 'product_details_page_browse_rate', format: 'percent' },
    { name: 'Button Click', key: 'button_click', format: 'number' },
    { name: 'Button Click Rate', key: 'button_click_rate', format: 'percent' },
    { name: 'Click Rate', key: 'clicks_rate', format: 'percent' },
    { name: 'Clicks', key: 'clicks', format: 'number' },
    { name: 'Conversion', key: 'conversion', format: 'number' },
    { name: 'Cost Per Add To Cart Web Event', key: 'web_event_add_to_cart_cost_per', format: 'currency' },
    { name: 'Cost Per Add to Wishlist On Web', key: 'on_web_add_to_wishlist_cost_per', format: 'currency' },
    { name: 'Cost Per Browse Product Details Page', key: 'product_details_page_browse_cost_per', format: 'currency' },
    { name: 'Cost Per Button Click', key: 'button_click_cost_per', format: 'currency' },
    { name: 'Cost Per Click(CPC)', alias: 'CPC', key: 'clicks_cost_per', format: 'currency' },
    { name: 'Cost Per Form', key: 'form_cost_per', format: 'currency' },
    { name: 'Cost Per Mille(CPM)', alias: 'CPM', key: 'cpm', format: 'currency' },
    { name: 'Cost Per Offline Purchase', key: 'cost_per_offline_shopping_event', format: 'number' },
    { name: 'Cost Per Online Consult (CPL)', alias: 'CPL', key: 'online_consult_cost_per', format: 'currency' },
    { name: 'Cost Per Order On Web', key: 'on_web_order_cost_per', format: 'currency' },
    { name: 'Cost Per Page Browse', key: 'page_browse_view_cost_per', format: 'currency' },
    { name: 'Cost Per Search Page Event', key: 'page_event_search_cost_per', format: 'currency' },
    { name: 'Cost Per Subscribe On Web', key: 'on_web_subscribe_cost_per', format: 'currency' },
    { name: 'Cost Per User Registration', key: 'user_registration_cost_per', format: 'currency' },
    { name: 'Form', key: 'form', format: 'number' },
    { name: 'Form Rate', key: 'form_rate', format: 'percent' },
    { name: 'Frequency', key: 'frequency', format: 'decimal' },
    { name: 'Impressions', key: 'impressions', format: 'number' },
    { name: 'Offline Purchase', key: 'offline_shopping_events', format: 'number' },
    { name: 'Offline Purchase Rate', key: 'offline_shopping_event_rate', format: 'percent' },
    { name: 'Offline Purchase Value', key: 'offline_shopping_events_value', format: 'number' },
    { name: 'Online Consult (Leads)', alias: 'Leads', key: 'online_consult', format: 'number' },
    { name: 'Online Consult Rate (Leads Rate)', alias: 'Leads Rate', key: 'online_consult_rate', format: 'percent' },
    { name: 'Order On Web', key: 'on_web_order', format: 'number' },
    { name: 'Order Rate On Web', key: 'on_web_order_rate', format: 'percent' },
    { name: 'Page Browse View', key: 'page_browse_view', format: 'number' },
    { name: 'Page Browse View Rate', key: 'page_browse_view_rate', format: 'percent' },
    { name: 'Reach', key: 'reach', format: 'number' },
    { name: 'Result', key: 'result', format: 'number' },
    { name: 'Search Page Event', key: 'page_event_search', format: 'number' },
    { name: 'Search Page Event Rate', key: 'page_event_search_rate', format: 'percent' },
    { name: 'Spend', key: 'spend', format: 'currency' },
    { name: 'Subscribe On Web', key: 'on_web_subscribe', format: 'number' },
    { name: 'Subscribe Rate On Web', key: 'on_web_subscribe_rate', format: 'percent' },
    { name: 'Value Per Offline Purchase', key: 'value_per_offline_shopping_event', format: 'number' },
    { name: 'User Registration', key: 'user_registration', format: 'number' },
    { name: 'User Registration Rate', key: 'user_registration_rate', format: 'percent' },
];

export default AdvertisingMetrics;
