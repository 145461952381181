<template>
    <div id="page-nav-actions">
        <ul
            class="action-menu"
            :style="`top: ${ topPx }`">
            <li
                class="action"
                @click="duplicatePage(pageIndex)">
                Duplicate
            </li>
            <div class="break" />
            <li
                class="action"
                @click="movePage({pageIndex: pageIndex, moveTo: 'up'})">
                Move up
            </li>
            <li
                class="action"
                @click="movePage({pageIndex: pageIndex, moveTo: 'down'})">
                Move down
            </li>
            <div class="break" />
            <li
                class="action"
                @click="movePage({pageIndex: pageIndex, moveTo: 'top'})">
                Move to top
            </li>
            <li
                class="action"
                @click="movePage({pageIndex: pageIndex, moveTo: 'bottom'})">
                Move to bottom
            </li>
            <div class="break" />
            <li
                class="action"
                @click="$emit('showPageSettings',pageIndex)">
                Page Settings
            </li>
            <li
                class="action delete"
                @click="deletePage(pageIndex)">
                Delete
            </li>
        </ul>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
    name: 'PageNavActions',
    props: {
        value: {
            type: Boolean,
            default: false
        },
        top: {
            type: Number,
            required: true
        },
        pageIndex: {
            type: Number,
            required: true
        }
    },
    computed: {
        ...mapState({
            activePageIndex: (state) => state.report.activePageIndex,
        }),
        topPx() {
            return this.top+ 'px';
        }
    },
    methods: {
        ...mapActions([
            'setActivePageIndex',
            'duplicatePage',
            'movePage',
            'deletePage',
        ]),
        showPageSettings() {

        }
    }
};
</script>

<style lang="scss" scoped>
.action-menu {
    position: fixed;
    left: 165px;
    z-index: 30;
    padding: 5px 0;
    background: $white;
    list-style: none;
    border-radius: 5px;
    white-space: nowrap;
    filter: drop-shadow(0 0 5px rgba(0,0,0,0.25));
    .action {
        padding: 5px 15px;
        font-size: 12px;

        &:hover {
            cursor: pointer;
            color: $blue-primary;
        }

        &.delete:hover {
            color: $carnation;
        }
    }
    .break {
        border-bottom: 1px solid #979797;
        margin: 5px 0;
        width: 100%;
    }

    &::before {
        display: block;
        position: absolute;
        left: -10px;
        top: 5px;
        content: '';
        width: 0;
        height: 0;
        border-width: 10px 10px 10px 0;
        border-color: transparent $white transparent transparent;
        border-style: solid;
    }
}
</style>