
<template>
<!-- eslint-disable -->
<div class="widget-region">
    <template v-if="layout == 'Full'">
        <svg width="106px" height="78px" viewBox="0 0 106 78" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(1, 1)">
                <g stroke="#8F9EA6">
                    <rect class="background" y="0" width="104" height="76"></rect>
                    <rect class="region active" x="7.5" y="6.5" width="90" height="63"></rect>
                </g>
                <text class="label active" font-family="OpenSans-Regular, Open Sans" font-size="15" font-weight="normal">
                    <tspan x="48" y="44">1</tspan>
                </text>
            </g>
        </svg>
    </template>
    <template v-if="layout == 'TwoUp'">
        <svg width="106px" height="78px" viewBox="0 0 106 78" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(1, 1)">
                <g class="Group-9" stroke="#8F9EA6">
                    <rect class="background" x="0" y="0" width="104" height="76"></rect>
                    <rect class="region" :class="{active: region == 0}" x="7" y="7.5" width="90" height="28"></rect>
                    <rect class="region" :class="{active: region == 1}" x="7" y="40.5" width="90" height="28"></rect>
                </g>
                <text class="label" :class="{active: region == 0}" font-family="OpenSans-Regular, Open Sans" font-size="15" font-weight="normal">
                    <tspan x="48" y="27">1</tspan>
                </text>
                <text class="label" :class="{active: region == 1}" font-family="OpenSans-Regular, Open Sans" font-size="15" font-weight="normal">
                    <tspan x="48" y="60">2</tspan>
                </text>
            </g>
        </svg>
    </template>
    <template v-else-if="layout == 'FourUp'">
        <svg width="106px" height="78px" viewBox="0 0 106 78" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(1, 1)">
                <g stroke="#8F9EA6">
                    <rect class="background" x="0" y="0" width="104" height="76"></rect>
                    <rect class="region" :class="{active: region == 0}" x="7" y="5.5" width="42" height="30"></rect>
                    <rect class="region" :class="{active: region == 1}" x="55" y="5.5" width="42" height="30"></rect>
                    <rect class="region" :class="{active: region == 2}" x="7" y="40.5" width="42" height="30"></rect>
                    <rect class="region" :class="{active: region == 3}" x="55" y="40.5" width="42" height="30"></rect>
                </g>
                <text class="label" :class="{active: region == 0}" font-family="OpenSans-Regular, Open Sans" font-size="15" font-weight="normal">
                    <tspan x="25" y="26">1</tspan>
                </text>
                <text class="label" :class="{active: region == 1}" font-family="OpenSans-Regular, Open Sans" font-size="15" font-weight="normal">
                    <tspan x="71" y="26">2</tspan>
                </text>
                <text class="label" :class="{active: region == 2}" font-family="OpenSans-Regular, Open Sans" font-size="15" font-weight="normal">
                    <tspan x="25" y="61">3</tspan>
                </text>
                <text class="label" :class="{active: region == 3}" font-family="OpenSans-Regular, Open Sans" font-size="15" font-weight="normal">
                    <tspan x="71" y="61">4</tspan>
                </text>
            </g>
        </svg>
    </template>
    <template v-else-if="layout == 'TwoOne'">
        <svg width="106px" height="78px" viewBox="0 0 106 78" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(1, 1)">
                <g stroke="#8F9EA6">
                    <rect class="background" x="0" y="0" width="104" height="76"></rect>
                    <rect class="region" :class="{active: region == 0}" x="7" y="5.5" width="42" height="30"></rect>
                    <rect class="region" :class="{active: region == 1}" x="55" y="5.5" width="42" height="30"></rect>
                    <rect class="region" :class="{active: region == 2}" x="7" y="40.5" width="90" height="28"></rect>
                </g>
                <text class="label" :class="{active: region == 0}" font-family="OpenSans-Regular, Open Sans" font-size="15" font-weight="normal">
                    <tspan x="25" y="26">1</tspan>
                </text>
                <text class="label" :class="{active: region == 1}" font-family="OpenSans-Regular, Open Sans" font-size="15" font-weight="normal">
                    <tspan x="71" y="26">2</tspan>
                </text>
                <text class="label" :class="{active: region == 2}" font-family="OpenSans-Regular, Open Sans" font-size="15" font-weight="normal">
                    <tspan x="48" y="60">3</tspan>
                </text>
            </g>
        </svg>
    </template>
    <template v-else-if="layout == 'OneTwo'">
        <svg width="106px" height="78px" viewBox="0 0 106 78" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(-36, -352)">
                <g class="Group-10" transform="translate(37, 353)">
                    <g class="Group-9" stroke="#8F9EA6">
                        <rect class="background" x="0" y="0" width="104" height="76"></rect>
                        <rect class="region" :class="{active: region == 0}" x="7" y="6.5" width="90" height="28"></rect>
                        <rect class="region" :class="{active: region == 1}" x="7" y="39.5" width="42" height="30"></rect>
                        <rect class="region" :class="{active: region == 2}" x="55" y="39.5" width="42" height="30"></rect>
                    </g>
                    <text class="label" :class="{active: region == 0}" font-family="OpenSans-Regular, Open Sans" font-size="15" font-weight="normal">
                        <tspan x="48" y="26">1</tspan>
                    </text>
                    <text class="label" :class="{active: region == 1}" font-family="OpenSans-Regular, Open Sans" font-size="15" font-weight="normal">
                        <tspan x="25" y="60">2</tspan>
                    </text>
                    <text class="label" :class="{active: region == 2}" font-family="OpenSans-Regular, Open Sans" font-size="15" font-weight="normal">
                        <tspan x="71" y="60">3</tspan>
                    </text>
                </g>
            </g>
        </svg>
    </template>
</div>
<!-- eslint-enable -->
</template>

<script>
export default {
    name: 'WidgetRegion',
    props: {
        layout: {
            type: String,
            required: true
        },
        region: {
            type: Number,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
.background{
    fill: $white;
}
.region {
    fill: #F7F8FC;

    &.active {
        fill: #00A2EA;
    }
}
.label {
    fill: #8F9EA6;

    &.active {
        fill: #FFFFFF;
    }
}
.widget-region {
    text.active tspan {
        font-weight: 900;
    }
}
</style>