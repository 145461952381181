// New metrics should be added in alphabetical order
const AdvertisingMetrics = [
    { name: 'Add to Wishlist Conversion Rate', key: 'conversion_add_to_wishlist_rate', format: 'percent' },
    { name: 'Add To Wishlist Conversions', key: 'conversion_add_to_wishlist', format: 'number' },
    { name: 'Content Views', key: 'conversion_view_content', format: 'number' },
    { name: 'Cost Per Add to Wishlist', key: 'conversion_add_to_wishlist_cost_per', format: 'currency' },
    { name: 'Cost Per Content View(CPCV)', alias: 'CPCV', key: 'conversion_view_content_cost_per', format: 'currency' },
    { name: 'Cost Per Lead(CPL)', alias: 'CPL', key: 'conversion_sign_ups_cost_per', format: 'currency' },
    { name: 'Cost Per Mille(CPM)', alias: 'CPM', key: 'cpm', format: 'currency' },
    { name: 'Cost Per Native Leads', key: 'native_leads_cost_per', format: 'currency' },
    { name: 'Cost Per Offline Purchase', key: 'cps', format: 'currency' },
    { name: 'Cost Per Page View(CPPV)', alias: 'CPPV', key: 'conversion_page_views_cost_per', format: 'currency' },
    { name: 'Cost Per Quartile 1', key: 'quartile_1_cost_per', format: 'currency' },
    { name: 'Cost Per Quartile 2', key: 'quartile_2_cost_per', format: 'currency' },
    { name: 'Cost Per Quartile 3', key: 'quartile_3_cost_per', format: 'currency' },
    { name: 'Cost Per Save', key: 'saves_cost_per', format: 'currency' },
    { name: 'Cost Per Share', key: 'shares_cost_per', format: 'currency' },
    { name: 'Cost Per Swipe', key: 'swipes_cost_per', format: 'currency' },
    { name: 'Cost Per Video View', key: 'video_views_cost_per', format: 'currency' },
    { name: 'Cost Per View Completion', key: 'view_completion_cost_per', format: 'currency' },
    { name: 'Impressions', key: 'impressions', format: 'number' },
    { name: 'Native Lead Rate', key: 'native_leads_rate', format: 'percent' },
    { name: 'Native Leads', key: 'native_leads', format: 'number' },
    { name: 'Offline Purchase', key: 'conversion_purchases', format: 'number' },
    { name: 'Offline Purchase Rate', key: 'conversion_purchases_rate', format: 'percent' },
    { name: 'Page View Conversion Rate', key: 'conversion_page_views_rate', format: 'percent' },
    { name: 'Page Views', key: 'conversion_page_views', format: 'number' },
    { name: 'Quartile 1', key: 'quartile_1', format: 'number' },
    { name: 'Quartile 1 Rate', key: 'quartile_1_rate', format: 'percent' },
    { name: 'Quartile 2', key: 'quartile_2', format: 'number' },
    { name: 'Quartile 2 Rate', key: 'quartile_2_rate', format: 'percent' },
    { name: 'Quartile 3', key: 'quartile_3', format: 'number' },
    { name: 'Quartile 3 Rate', key: 'quartile_3_rate', format: 'percent' },
    { name: 'Save Rate', key: 'saves_rate', format: 'percent' },
    { name: 'Saves', key: 'saves', format: 'number' },
    { name: 'Screen Time', key: 'screen_time', format: 'number' },
    { name: 'Search Conversion Rate', key: 'conversion_searches_rate', format: 'percent' },
    { name: 'Search Conversions', key: 'conversion_searches', format: 'number' },
    { name: 'Search Cost Per Conversion', key: 'conversion_searches_cost_per', format: 'currency' },
    { name: 'Share Rate', key: 'shares_rate', format: 'percent' },
    { name: 'Shares', key: 'shares', format: 'number' },
    { name: 'Sign Up Conversion Rate', key: 'conversion_sign_ups_rate', format: 'percent' },
    { name: 'Spend', key: 'spend', format: 'currency' },
    { name: 'Swipe Rate', key: 'swipes_rate', format: 'percent' },
    { name: 'Swipes', key: 'swipes', format: 'number' },
    { name: 'Total Leads', key: 'conversion_sign_ups', format: 'number' },
    { name: 'Video View', key: 'video_views', format: 'number' },
    { name: 'Video View Rate', key: 'video_views_rate', format: 'percent' },
    { name: 'View Completion', key: 'view_completion', format: 'number' },
    { name: 'View Completion Rate', key: 'view_completion_rate', format: 'percent' },
    { name: 'View Content Conversion Rate', key: 'conversion_view_content_rate', format: 'percent' },
    { name: 'View Time', key: 'view_time', format: 'number' },
];

export default AdvertisingMetrics;
