<template>
    <div
        class="page-nav"
        @scroll="throttleHidePageActions">
        <ul class="pages">
            <draggable v-model="pageList">
                <transition-group>
                    <li
                        v-for="(page,p) in pageList"
                        :key="'page' + p"
                        class="page">
                        <p class="page-name">
                            {{ page.title }}
                        </p>
                        <div class="preview-container">
                            <div
                                class="page-preview"
                                :class="getPageClassName(p, page.meta.layout)"
                                @click.stop="setPageActive(p)" />
                            <div class="actions">
                                <button
                                    class="kebab"
                                    @click.stop="showPageActions($event, p)">
                                    <!-- eslint-disable-next-line -->
                                    <svg height="20" viewBox="0 0 4 20" width="4" xmlns="http://www.w3.org/2000/svg"><g fill="#8f9ea6" fill-rule="evenodd"><circle cx="2" cy="2" r="2"/><circle cx="2" cy="10" r="2"/><circle cx="2" cy="18" r="2"/></g></svg>
                                </button>
                                <div class="arrows">
                                    <!-- eslint-disable-next-line -->
                                    <svg height="20" viewBox="0 0 8 20" width="8" xmlns="http://www.w3.org/2000/svg"><g fill="#8f9ea6" transform="translate(.5)"><path d="m4.87254902 19.8974359v-3.4444444h2.12745098l-3.5-4.7863248-3.5 4.7863248h2.12745098v3.4444444z" transform="matrix(-1 0 0 -1 7 31.564102)"/><path d="m4.87254902 8.23076923v-3.44444444h2.12745098l-3.5-4.78632479-3.5 4.78632479h2.12745098v3.44444444z"/></g></svg>
                                </div>
                            </div>
                        </div>
                    </li>
                </transition-group>
            </draggable>
        </ul>
        <button
            class="add-page"
            @click="$emit('addPage')">
            Add Page(s)
        </button>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { throttle } from 'lodash';
import draggable from 'vuedraggable';

export default {
    name: 'PageNav',
    components: {
        draggable
    },
    computed: {
        ...mapState({
            template: (state) => state.report.template,
            activePageIndex: (state) => state.report.activePageIndex,
        }),
        pageList: {
            get() {
                return this.template.pages;
            },
            set(pageList) {
                this.reorderPages(pageList);
            }
        }
    },
    methods: {
        ...mapActions([
            'setActivePageIndex',
            'movePage',
            'reorderPages',
        ]),
        getPageClassName(p, layout) {
            let name = layout.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
            if(p == this.activePageIndex) {
                name+=' active';
            }
            return name;
        },
        setPageActive(index) {
            this.setActivePageIndex(index);
            this.scrollToPage(index);
        },
        scrollToPage(index) {
            const pageHeight = 940;
            window.scrollTo({ top: index * pageHeight, behavior: 'smooth' });
        },
        showPageActions(e, pageIndex) {
            const kebab = e.target.closest('.kebab');
            const rect = kebab.getBoundingClientRect();
            this.$emit('showPageNavActions', { top: rect.y-5, pageIndex });
        },
        throttleHidePageActions: throttle(function() {
            this.$emit('hidePageNavActions');
        }, 1000)
    }

};
</script>

<style lang="scss" scoped>
.page-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
}
.pages {
    list-style: none;
    padding: 0;
}
.page {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.page-name {
    font-size: 10px;
    color: #03A2EA;
    text-align: center;
    margin: 15px 14px 5px 0;
    white-space: nowrap;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.preview-container {
    display: flex;
    flex-direction: row;
}
.page-preview {
    cursor: pointer;
    background: $white;
    height: 80px;
    width: 110px;
    border: 1px solid #8F9EA6;
    background: url('./assets/layouts/full.svg') center center no-repeat;

    &.active {
        border: 3px solid #03A2EA;
    }

    &.four-up {
        background: url('./assets/layouts/four-up.svg') center center no-repeat;
    }
    &.one-two {
        background: url('./assets/layouts/one-two.svg') center center no-repeat;
    }
    &.two-one {
        background: url('./assets/layouts/two-one.svg') center center no-repeat;
    }
    &.two-up {
        background: url('./assets/layouts/two-up.svg') center center no-repeat;
    }
}
.actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .kebab {
        padding: 0 5px;
        &:hover {
            svg g {
                fill: #03A2EA;
            }
        }
    }
    .arrows {
        padding: 0 5px;
        margin-bottom: 5px;
        display: flex;
        flex-direction: column;
        &:hover {
            cursor: grab;
        }
        .up,
        .down {
            line-height: 10px;
            &:hover {
            svg path {
                fill: #03A2EA;
            }
        }
        }
    }
}
.add-page {
    margin-top: 15px;
    padding: 8px 10px;
    border-radius: 5px;
    background: #03A2EA;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    color: $white;
    text-align: center;
}
</style>